.hiddenInput {
    background-color: transparent;
    color: transparent;
    caret-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}
.App {
    background-color:#0763E7;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
}
   
.appTittles {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
}

.duxLogo {
    width: 60%;
}
